import React from "react";
import styled from "styled-components";
import { useLocationCountry } from "../../hooks";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import Header from "../../components/sections/agents/header";
import Services from "../../components/sections/agents/services";
import Footer from "../../components/sections/footer";
import BuildBusiness from "../../components/sections/agents/build-business";
import ToolsToSucceed from "../../components/sections/agents/tools-to-succeed";
import GettingStarted from "../../components/sections/agents/getting-started";
import Testimonials from "../../components/sections/testimonials";

const TestimonialsWrapper = styled.div`
  position: relative;
`;

const testimonialData = [
  {
    id: 0,
    description: `OPay is a key player in the mobile money business, driving financial inclusion in Nigeria and banking the unbanked.  We’ve created an innovative means to help our merchants generate a source of income by providing financial services to customers. Here is a testimony from one of our merchants; 
  `,
    attestantName: "",
    attestantTitle: "OPay merchant",
    videoID: "LU8ne5_CHrE",
  },
];

const MerchantsPage = () => {
  const { isPakistan } = useLocationCountry();

  return (
    <Layout>
      <SEO title="OPay Merchants" />
      <Navigation background="white" />
      <Header />
      <BuildBusiness />
      <Services />
      <ToolsToSucceed />
      <TestimonialsWrapper>
        {isPakistan ? (
          <Testimonials items={testimonialData} title={`We are trusted by millions of customers\nworldwide`} />
        ) : (
          <Testimonials items={testimonialData} title={`We are trusted by over 5 million\nNigerians`} />
        )}
      </TestimonialsWrapper>
      <GettingStarted />
      <Footer />
    </Layout>
  );
};

export default MerchantsPage;
